import * as React from "react";
import { FunctionComponent, useState } from "react";
import {
  Button,
  Heading,
  Input,
  Page,
  PageContent,
  PageSection,
  Paragraph,
  Tile
} from "../components";
import { useStaticQuery, graphql } from "gatsby";

import "./Contact.css";

const Recaptcha = require("react-recaptcha");

function formatCityStateZip({
  city,
  state,
  zipCode
}: {
  city: string;
  state: string;
  zipCode: string;
}) {
  if (!(city && state && zipCode)) {
    return;
  }
  return (
    <React.Fragment>
      <br />
      {city}
      {city && state && `, `}
      {state}
      {city && zipCode && ` `}
      {zipCode}
    </React.Fragment>
  );
}

const ContactPage: FunctionComponent = (): JSX.Element => {
  const data = useStaticQuery(graphql`
    query {
      officeLocations: contentfulCollection(name: { eq: "Office Locations" }) {
        entries {
          ... on ContentfulOfficeContact {
            id
            title
            addressLine1
            addressLine2
            city
            state
            zipCode
            phone
            fax
          }
        }
      }
      allContentfulGeneralEditableField {
        edges {
          node {
            title
            pageSection
            childContentfulGeneralEditableFieldContentRichTextNode {
              childContentfulRichText {
                html
              }
            }
          }
        }
      }
      contactUsHomeUpgrade: contentfulGeneralEditableField(
        pageSection: { eq: "contactUsHomeUpgrade" }
      ) {
        title
        content {
          childContentfulRichText {
            html
          }
        }
      }
      homeUpgradeImage: contentfulAsset(
        title: { eq: "Contact > Request Home Upgrade" }
      ) {
        description
        file {
          url
        }
      }
      contactUsImage: contentfulAsset(title: { eq: "Contact > Contact Us" }) {
        description
        file {
          url
        }
      }
    }
  `);

  const {
    contactUsImage,
    officeLocations,
    contactUsHomeUpgrade,
    homeUpgradeImage
  } = data;

  const generalEditableFields = data.allContentfulGeneralEditableField.edges;
  const headerContent = generalEditableFields.filter(
    field => field.node.pageSection === "contactUsHeader"
  );
  const questionAboutCommunityContent = generalEditableFields.filter(
    field => field.node.pageSection === "contactUsCommunityQuestion"
  );
  const questionAboutAccountContent = generalEditableFields.filter(
    field => field.node.pageSection === "contactUsAccountQuestion"
  );
  const afterHourEmergencyContent = generalEditableFields.filter(
    field => field.node.pageSection === "contactUsAfterHourEmergency"
  );

  const [isCaptchaVerified, setCaptchaVerified] = useState(false);
  const [captchaToken, setCaptchaToken] = useState("");

  const recaptchaVerifyCallback = response => {
    setCaptchaVerified(true);
    setCaptchaToken(response);
  };

  const recaptchaExpiredCallback = _response => {
    setCaptchaVerified(false);
    setCaptchaToken("");
  };

  const contactTiles = officeLocations.entries.map(officeContact => {
    const {
      id,
      title,
      addressLine1,
      addressLine2,
      city,
      zipCode,
      state,
      phone,
      fax
    } = officeContact;
    return (
      <Tile key={`office-contact-${id}`}>
        <Heading level={5}>{title}</Heading>
        <table style={{ width: "100%" }}>
          <tr>
            <td>Address</td>
            <td>
              {addressLine1 && addressLine1}
              {addressLine2 && (
                <React.Fragment>
                  <br /> {addressLine2}
                </React.Fragment>
              )}
              {formatCityStateZip({ city, state, zipCode })}
            </td>
          </tr>
          <tr>
            <td>Phone</td>
            <td>{phone}</td>
          </tr>
          <tr>
            <td>Fax</td>
            <td>{fax}</td>
          </tr>
        </table>
      </Tile>
    );
  });

  return (
    <Page layout="default" title="Contact Us">
      <div className="contact--hero">
        <div>
          <img
            className="find-your-community--sidebar-img"
            src={contactUsImage.file.url}
            alt={contactUsImage.description}
          />
        </div>
        <div>
          <Heading level={1}>Contact Us</Heading>
          {headerContent.map((content, index) => (
            <div
              dangerouslySetInnerHTML={{
                __html:
                  content.node
                    .childContentfulGeneralEditableFieldContentRichTextNode
                    .childContentfulRichText.html
              }}
              key={`contact-us-header-${index}`}
            />
          ))}
          <Heading level={3}>Have questions about—</Heading>
          <div className="flex-wrapper">
            <div className="contact-split">
              {questionAboutCommunityContent.map((content, index) => (
                <div key={`community-question-${index}`}>
                  <Heading level={4}>{content.node.title}</Heading>
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        content.node
                          .childContentfulGeneralEditableFieldContentRichTextNode
                          .childContentfulRichText.html
                    }}
                  />
                </div>
              ))}
              <Button href="/find-a-community">
                Search For Your Community
              </Button>
            </div>

            <div className="contact-split">
              {questionAboutAccountContent.map((content, index) => (
                <div key={`account-question-${index}`}>
                  <Heading level={4}>{content.node.title}</Heading>
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        content.node
                          .childContentfulGeneralEditableFieldContentRichTextNode
                          .childContentfulRichText.html
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <PageSection
        id="home-upgrade"
        style={{
          backgroundImage: `url(${homeUpgradeImage.file.url})`
        }}
        className="request-home-upgrade contact-section"
      >
        <Tile>
          <Heading level={3}>{contactUsHomeUpgrade.title}</Heading>
          <div
            dangerouslySetInnerHTML={{
              __html: contactUsHomeUpgrade.content.childContentfulRichText.html
            }}
          />
          <form
            onSubmit={event => {
              event.preventDefault();
              window.location = `/find-a-community/?query=${
                event.target.query.value
              }`;
            }}
          >
            <Input
              placeholder="Search by address or building name"
              name="query"
            />
            <Button type="submit">Find My Community</Button>
          </form>
        </Tile>
      </PageSection>

      <PageSection style={{ textAlign: "center" }}>
        {afterHourEmergencyContent.map((content, index) => (
          <div key={`community-question-${index}`}>
            <Heading level={3}>{content.node.title}</Heading>
            <div
              dangerouslySetInnerHTML={{
                __html:
                  content.node
                    .childContentfulGeneralEditableFieldContentRichTextNode
                    .childContentfulRichText.html
              }}
            />
          </div>
        ))}
      </PageSection>
      <div className="flex-wrapper dark-gradient-section">
        <div className="tile tile--two-thirds">
          <Heading level={3}>
            Are you interested in what Hawaiiana can provide for your property?
          </Heading>
          <div>
            <Paragraph>
              {`Submit a request to receive a personalized proposal for your property`}
            </Paragraph>
            <Button href="/request-a-proposal">Request a Proposal</Button>
          </div>
        </div>
      </div>
      <form
        className="tile--two-thirds"
        method="POST"
        action={`${process.env.GATSBY_HMCMGT_API_GATEWAY_URL}/contact-us`}
      >
        <Heading style={{ textAlign: "center" }} level={2} id="submit-form">
          Comments or Concerns?
        </Heading>
        <Paragraph style={{ textAlign: "center" }}>
          {`Send us a message and we’ll get back to you as quick as we can`}
        </Paragraph>
        <fieldset>
          <div>
            <div className="input--split">
              <label htmlFor="first_name">First Name</label>
              <Input id="first_name" name="first_name" required />
            </div>
            <div className="input--split">
              <label htmlFor="last_name">Last Name</label>
              <Input id="last_name" name="last_name" required />
            </div>
          </div>

          <div>
            <label htmlFor="association">Community Name (Optional)</label>
            <Input id="association" name="community_name" />
          </div>

          <div>
            <label htmlFor="address">Address (Optional)</label>
            <Input id="address" name="address_street" />
          </div>

          <div>
            <label htmlFor="phone_number">Phone Number (Optional)</label>
            <Input id="phone_number" name="phone_number" type="text" />
          </div>

          <div>
            <label htmlFor="email_address">Email</label>
            <Input id="email_address" name="email_address" required />
          </div>

          <div>
            <label htmlFor="message">Message</label>
            <Input id="message" type="textarea" name="message" required />
          </div>
        </fieldset>

        <div id="recaptcha-container">
          {typeof window !== "undefined" && (
            <Recaptcha
              elementId="recaptcha-container"
              render="explicit"
              sitekey={process.env.GATSBY_GOOGLE_RECAPTCHA_KEY}
              verifyCallback={recaptchaVerifyCallback}
              onloadCallback={console.debug}
              expiredCallback={recaptchaExpiredCallback}
            />
          )}
        </div>
        <Input
          id="captcha_token"
          name="captcha_token"
          type="hidden"
          value={captchaToken}
          required
        />

        {isCaptchaVerified && (
          <p style={{ textAlign: "center" }}>
            <Button type="submit">Submit</Button>
          </p>
        )}
      </form>
      <PageSection
        className="section-light-gradient"
        style={{ textAlign: "center" }}
      >
        <Heading className="contact-heading" level={3}>
          Our Offices by Island
        </Heading>
        <Paragraph>
          Direct general inquiries to the office nearest you
        </Paragraph>
        <PageContent style={{ maxWidth: 720 }}>
          <div className="contact-island-directory">{contactTiles}</div>
        </PageContent>
      </PageSection>
    </Page>
  );
};

export default ContactPage;
